var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "DateMatter" },
    [
      _c("MemoMatter", {
        attrs: { "memo-matter-list": _vm.memoMatterList },
        on: {
          refresh: function ($event) {
            return _vm.$emit("refresh")
          },
          handleEdit: _vm.handleEdit,
        },
      }),
      _c("SystemMatter", {
        attrs: { "system-matter-list": _vm.systemMatterList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }